<template>
  <UModal v-model="showDialog" :prevent-close="isDeleteModalOpen">
    <UCard :ui="{ ring: '', divide: 'divide-y divide-gray-100 dark:divide-gray-800' }">
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-white">
            Start from a saved prompt
          </h3>
          <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark-20-solid" class="-my-1"
            @click="showDialog = false" />
        </div>
      </template>

      <div class="flex flex-col gap-4 -mt-6">
        <div class="flex w-full border-b border-gray-100">
          <button @click="activeTab = 'private'"
            class="px-4 py-3 hover:bg-gray-50 border-b-4 border-transparent transition-all duration-300" :class="activeTab === 'private' ? 'border-wobby-purple-400' : ''">
              My prompts
          </button>
          <button @click="activeTab = 'team'"
            class="px-4 py-3 hover:bg-gray-50 border-b-4 border-transparent transition-all duration-300" :class="activeTab === 'team' ? 'border-wobby-purple-400' : ''">
              Shared prompts
          </button>
        </div>

        <template v-if="activeTab == 'private'">
          <div class="minimal-scrollbar max-h-[300px] overflow-y-auto" v-if="myPrompts?.filter(p => p.scope.includes(openedIn)).length > 0">
            <button v-for="prompt in myPrompts?.filter(p => p.scope.includes(openedIn))" :key="prompt.id"
              @click="emit('selectPrompt', prompt.prompt); showDialog = false"
              class="flex items-start w-full px-3 py-2 mb-2 rounded border border-gray-100 hover:bg-gray-50 hover:border-gray-200 cursor-pointer">
              <div class="text-left">
                  <p><strong class="block text-sm text-gray-800">{{ prompt.title }}</strong></p>
                  <p class="w-full line-clamp-3 whitespace-pre-line text-sm mt-2 text-gray-700">
                    {{ prompt.prompt }}
                  </p>
                </div>
                
                <UPopover :popper="{ arrow: false }" class="ml-auto">
                  <button class="rounded-full hover:bg-gray-200 p-1 flex items-center justify-center">
                    <Icon name="heroicons:ellipsis-vertical" class="h-4 w-4 text-gray-400" aria-hidden="true" />
                  </button>
                  <template #panel>
                    <div class="flex flex-col gap-1 p-2">
                      <button class="flex items-center gap-2 text-gray-500 hover:text-gray-800 px-2 py-1" @click.stop="showEditPromptDialog(prompt)">
                        <Icon name="heroicons:pencil" class="h-4 w-4" aria-hidden="true" />
                        <span>Edit</span>
                      </button>
                      <button class="flex items-center gap-2 text-gray-500 hover:text-red-500 px-2 py-1" @click.stop="promptToDelete = prompt.id; isDeleteModalOpen = true">
                        <Icon name="heroicons:trash" class="h-4 w-4" aria-hidden="true" />
                        <span>Delete</span>
                      </button>
                    </div>
                  </template>
                </UPopover>
            </button>
          </div>
          <div class="text-gray-400" v-else>
            You have no saved prompts yet.
          </div>
        </template>

        <template v-else-if="activeTab == 'team'">
          <div class="minimal-scrollbar max-h-[300px] overflow-y-auto" v-if="teamPrompts?.filter(p => p.scope.includes(openedIn)).length > 0">
            <button v-for="prompt in teamPrompts?.filter(p => p.scope.includes(openedIn))" :key="prompt.id"
              @click="emit('selectPrompt', prompt.prompt); showDialog = false"
              class="flex items-start w-full px-3 py-2 mb-2 rounded border border-gray-100 hover:bg-gray-50 hover:border-gray-200 cursor-pointer">
                <div class="text-left">
                  <p><strong class="block text-sm text-gray-800">{{ prompt.title }}</strong></p>
                  <p class="w-full line-clamp-3 whitespace-pre-line text-sm mt-2 text-gray-700">
                    {{ prompt.prompt }}
                  </p>
                </div>
            </button>
          </div>
          <div class="text-gray-400" v-else>
            Your team has no saved prompts.
          </div>
        </template>
      </div>
    </UCard>
  </UModal>

  <DialogsEditPromptDialog ref="editPromptDialog" />

  <UModal v-model="isDeleteModalOpen">
    <UCard>
      <template #header>
        <h2 class="text-lg font-semibold">Delete Prompt</h2>
      </template>
      <p>Are you sure you want to delete this prompt?</p>
      <template #footer>
        <div class="flex justify-end gap-x-2">
          <UButton @click="isDeleteModalOpen = false" color="gray">Cancel</UButton>
          <UButton @click="deletePrompt" color="red">Delete</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup lang="ts">
import type { SavedPrompt, ModalElement } from '~/types';

enum OPENED_IN_OPTIONS {
  homeSearch = 'home-search',
  researchAssistant = 'research-assistant',
  chat = 'chat'
}

const identityStore = useIdentityStore();
const { getUserPrompts, deleteUserPrompt, getOrganizationPrompts } = useIdentityService();
const { user } = storeToRefs(identityStore);

const emit = defineEmits(['selectPrompt'])

const showDialog = ref(false)

const activeTab = ref('private')
const openedIn = ref<OPENED_IN_OPTIONS>(OPENED_IN_OPTIONS.homeSearch)

const myPrompts: Ref<SavedPrompt[]> = ref([])
const teamPrompts: Ref<SavedPrompt[]> = ref([])

const loadUserPrompts = async () => {
  myPrompts.value = await getUserPrompts(user.value!.id);
}

const loadTeamPrompts = async () => {
  teamPrompts.value = await getOrganizationPrompts(user.value!.organization!.id!);
}

watch(() => activeTab.value, async (tab) => {
  if (tab === 'team') {
    await loadTeamPrompts();
  }
})

const editPromptDialog = ref<ModalElement | null>(null)
const showEditPromptDialog = (prompt: SavedPrompt) => {
  editPromptDialog.value?.show(prompt);
}

const isDeleteModalOpen = ref(false)
const promptToDelete = ref<string | null>(null)
const deletePrompt = async () => {
  await deleteUserPrompt(user.value!.id, promptToDelete.value!);
  myPrompts.value = myPrompts.value.filter(p => p.id !== promptToDelete.value);
  isDeleteModalOpen.value = false;
  promptToDelete.value = null;
}

const show = (openedInValue: OPENED_IN_OPTIONS) => {
  openedIn.value = openedInValue;
  showDialog.value = true;
  loadUserPrompts();
}

defineExpose({
  show
})
</script>